<template>
  <br />

  <div class="container">
    <div class="card modern-card shadow-sm">
      <div class="card-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-info-circle"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0"></path>
          <path d="M12 9h.01"></path>
          <path d="M11 12h1v4h1"></path></svg
        >&nbsp;
        <h5 class="card-title">About</h5>
      </div>
      <div class="card-body">
        <p>
          Delve into the universe Microsoft's security & patch updates with
          <strong>PatchaPalooza</strong>, for streamlined vulnerability
          management. Using Microsoft's MSRC CVRF API, this tool caters
          exclusively to cybersecurity professionals, bringing forth an
          intuitive interface that delivers essential insights without
          overwhelming users with tech jargon.
        </p>

        <h3 class="mt-5">Features:</h3>
        <ul class="mt-3">
          <li>
            <strong>Instant Insights</strong>: Gone are the days of waiting.
            With an integrated live loading mechanism, you're always informed
            about the current situation in Microsofts security updates.
          </li>
          <li>
            <strong>Easy Navigation</strong>: Whether you're looking to dive
            deep into the statistics of vulnerabilities or just want to get a
            general overview, PatchaPalooza's intuitive navigation menu ensures
            that you're never more than a click away from your desired
            information.
          </li>
          <li>
            <strong>Detailed Analysis with a Dash of Simplicity</strong>: Dive
            deep into the intricacies of vulnerabilities, from overall
            statistics to specific threat categories. Whether they're being
            actively exploited or not, every vulnerability is laid out for you
            in a clear, concise manner. Interactive tables, enriched with direct
            links to Microsoft's database, allow you to further investigate any
            vulnerability that catches your eye.
          </li>
          <li>
            <strong>Monthly Summaries at Your Fingertips</strong>: With a
            built-in dropdown, you can seamlessly navigate through monthly
            summaries, ensuring you're always up to date with the latest trends
            and threats.
          </li>
          <li>
            <strong>Created with Passion</strong>: Developed by
            <a href="https://primepage.de/" target="_blank">Alexander Hagenah</a
            >, PatchaPalooza is not just a tool, but a commitment to enhance the
            cybersecurity landscape. And as a testament to its foundation, it
            proudly builds upon Microsoft's MSRC CVRF API.
          </li>
        </ul>

        <p class="mt-5">
          Discover, analyze, and strategize with <strong>PatchaPalooza</strong>.
        </p>
      </div>
    </div>
  </div>

  <div class="container mt-5">
    <div class="card modern-card shadow-sm">
      <div class="card-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-transform-filled"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="currentColor"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path
            d="M18 14a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z"
            stroke-width="0"
            fill="currentColor"
          ></path>
          <path
            d="M16.707 2.293a1 1 0 0 1 .083 1.32l-.083 .094l-1.293 1.293h3.586a3 3 0 0 1 2.995 2.824l.005 .176v3a1 1 0 0 1 -1.993 .117l-.007 -.117v-3a1 1 0 0 0 -.883 -.993l-.117 -.007h-3.585l1.292 1.293a1 1 0 0 1 -1.32 1.497l-.094 -.083l-3 -3a.98 .98 0 0 1 -.28 -.872l.036 -.146l.04 -.104c.058 -.126 .14 -.24 .245 -.334l2.959 -2.958a1 1 0 0 1 1.414 0z"
            stroke-width="0"
            fill="currentColor"
          ></path>
          <path
            d="M3 12a1 1 0 0 1 .993 .883l.007 .117v3a1 1 0 0 0 .883 .993l.117 .007h3.585l-1.292 -1.293a1 1 0 0 1 -.083 -1.32l.083 -.094a1 1 0 0 1 1.32 -.083l.094 .083l3 3a.98 .98 0 0 1 .28 .872l-.036 .146l-.04 .104a1.02 1.02 0 0 1 -.245 .334l-2.959 2.958a1 1 0 0 1 -1.497 -1.32l.083 -.094l1.291 -1.293h-3.584a3 3 0 0 1 -2.995 -2.824l-.005 -.176v-3a1 1 0 0 1 1 -1z"
            stroke-width="0"
            fill="currentColor"
          ></path>
          <path
            d="M6 2a4 4 0 1 1 -3.995 4.2l-.005 -.2l.005 -.2a4 4 0 0 1 3.995 -3.8z"
            stroke-width="0"
            fill="currentColor"
          ></path></svg
        >&nbsp;
        <h5 class="card-title">Changelog</h5>
      </div>
      <div class="card-body">
        <h3>12. February 2025 / Version 0.15</h3>
        <ul class="mt-3">
          <li>
            Added Exploits again. Now powered by
            <a href="https://vulncheck.com" target="_blank">VulnCheck.com</a>!
          </li>
          <li>Greatly improved caching for fast vulnerability insights.</li>
        </ul>
        <h3>10. February 2025 / Version 0.14</h3>
        <ul class="mt-3">
          <li>Code refactoring and component updates.</li>
          <li>Removed Exploits.</li>
          <li>
            Removed dedicated page for Patch Tuesday which can now be enabled
            via Toggle on Patches page.
          </li>
        </ul>
        <h3>22. May 2024 / Version 0.13</h3>
        <ul class="mt-3">
          <li>Optimized for MSRC API v3</li>
          <li>Code refactoring</li>
        </ul>
        <h3>04. February 2024 / Version 0.12</h3>
        <ul class="mt-3">
          <li>
            Enhanced UI for Vulnerabilities, Threat Categories & Affected
            Products.
          </li>
          <li>Enhanced Annual Patch Statistics to display 2024 as well.</li>
        </ul>
        <h3>19. January 2024 / Version 0.11</h3>
        <ul class="mt-3">
          <li>
            Added new Page "New Exploits" that exclusively lists recently
            published exploits that pertain to Microsoft-issued CVEs, offering a
            sorted view prioritizing those with the most community engagement in
            terms of GitHub stars.
          </li>
          <li>Several improvements.</li>
        </ul>
        <h3>09. January 2024 / Version 0.10</h3>
        <ul class="mt-3">
          <li>Fixed export of tables.</li>
          <li>Several improvements.</li>
        </ul>
        <h3>27. November 2023 / Version 0.9</h3>
        <ul class="mt-3">
          <li>
            Added new page "Patch Tuesday Insights" that focuses purely on
            Microsofts Patch Tuesday statistics.
          </li>
        </ul>
        <h3>15. November 2023 / Version 0.8</h3>
        <ul class="mt-3">
          <li>Added CVSS details when clicking the CVSS score.</li>
        </ul>
        <h3>14. November 2023 / Version 0.7</h3>
        <ul class="mt-3">
          <li>Added Info/FAQ to each vulnerability.</li>
        </ul>
        <h3>12. November 2023 / Version 0.6</h3>
        <ul class="mt-3">
          <li>Many UI enhancements.</li>
          <li>Fixed the search when there were no results returned.</li>
        </ul>
        <h3>10. November 2023 / Version 0.5</h3>
        <ul class="mt-3">
          <li>New Exploited / Not Exploited table that allows:</li>
          <ul>
            <li>
              Full-text search thorugh CVE, CVSS, Title, Threat Category &
              Product.
            </li>
            <li>Columns sortable.</li>
            <li>Export of the displayed, filtered or sorted items.</li>
          </ul>
        </ul>
        <h3>08. November 2023 / Version 0.4</h3>
        <ul class="mt-3">
          <li>
            Enhanced server-side caching (max. 6 hours) which is now more stable
            and reactive.
          </li>
          <li>Calculation of the next Microsoft Patch Tuesday.</li>
          <li>Should be more mobile friendly now.</li>
          <li>UI enhancements.</li>
        </ul>
        <h3>15. October 2023 / Version 0.3</h3>
        <ul class="mt-3">
          <li>
            Added server-side caching (max. 12 hours) to reduce the load on this
            server and the MSRC API.
          </li>
          <li>Added annual statistics of affected products.</li>
          <li>Added per-month statistics of affected products.</li>
        </ul>
        <h3 class="mt-5">14. October 2023 / Version 0.2</h3>
        <ul class="mt-3">
          <li>Added annual <a href="./statistics">statistics</a>.</li>
        </ul>
        <h3 class="mt-5">13. October 2023 / Version 0.1</h3>
        <ul class="mt-3">
          <li>Initial release.</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped>
.modern-card {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
