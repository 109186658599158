<template>
  <br />
  <div class="container mt-3">
    <div class="card modern-card shadow-sm h-100">
      <!-- Table Header -->
      <div
        class="card-header d-flex justify-content-between align-items-center"
      >
        <div class="d-flex align-items-center">
          <h5 class="card-title mb-0">
            Exploits ({{ filteredExploits.length }})
          </h5>
        </div>
        <div>
          <a
            href="https://vulncheck.com/"
            target="_blank"
            rel="noopener noreferrer"
            style="text-decoration: none; color: inherit"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 800.5 160.35"
              class="w-auto h-4"
            >
              <path
                d="M254.12,44.23l-25.3,58.09-25.07-58.09h-15.22l32.95,75.36h13.79l32.87-75.36h-14.01Zm61.05,17.88v29.01c0,11.51-6.36,17.35-15.75,17.35-8.48,0-13.41-4.92-13.41-15.38v-30.98h-13.48v32.8c0,17.34,10,25.45,24.77,25.45,7.42,0,14.24-2.8,18.56-8.1v7.35h12.8V62.11h-13.48Zm31.13,57.49h13.48V39.69h-13.48V119.6Zm63.7-58.17c-8.33,0-15.38,2.8-19.77,8.1v-7.42h-12.8v57.49h13.41v-29.09c0-11.51,6.51-17.35,16.06-17.35,8.63,0,13.71,4.92,13.71,15.15v31.28h13.41v-32.95c0-17.35-10.23-25.22-24.01-25.22h0Zm77.33,59.23c12.42,0,22.95-4.39,29.92-12.57l-9.01-8.63c-5.53,5.98-12.27,8.94-20.15,8.94-15.6,0-26.89-10.98-26.89-26.51s11.29-26.51,26.89-26.51c7.88,0,14.62,2.95,20.15,8.86l9.01-8.48c-6.97-8.18-17.5-12.65-29.84-12.65-23.1,0-40.37,16.28-40.37,38.78s17.27,38.78,40.3,38.78h0Zm74.76-59.23c-8.03,0-14.77,2.58-19.16,7.35v-29.09h-13.48V119.6h13.48v-29.09c0-11.51,6.44-17.35,15.98-17.35,8.63,0,13.71,4.92,13.71,15.15v31.28h13.48v-32.95c0-17.35-10.23-25.22-24.01-25.22h0Zm95.66,29.69c0-17.95-12.27-29.69-29.01-29.69s-29.77,12.27-29.77,29.39,12.5,29.54,31.81,29.54c9.85,0,17.95-3.26,23.1-9.39l-7.2-8.33c-4.09,4.24-9.16,6.29-15.6,6.29-10,0-17.04-5.38-18.63-13.79h45.14c.08-1.29,.15-2.88,.15-4.01h0Zm-29.01-18.94c8.79,0,15.15,5.76,16.21,14.01h-32.65c1.36-8.41,7.65-14.01,16.44-14.01h0Zm68.32,48.17c11.21,0,20.15-4.85,24.54-13.48l-10.3-6.06c-3.48,5.53-8.63,7.95-14.32,7.95-9.92,0-17.5-6.74-17.5-17.95s7.57-17.95,17.5-17.95c5.68,0,10.83,2.42,14.32,7.95l10.3-6.06c-4.39-8.71-13.33-13.33-24.54-13.33-18.18,0-31.13,12.19-31.13,29.39s12.95,29.54,31.13,29.54h0Zm79.08-.76h16.36l-26.28-33.4,24.01-24.09h-16.13l-27.95,25.83V39.69h-13.41V119.6h13.41v-15.22l10.07-9.47,19.92,24.69Z"
                class="fill-current text-gray-700 dark:text-white"
              ></path>
              <path
                d="M.83,68.4c-.53,3.86-.83,7.8-.83,11.74,0,19.24,6.74,36.89,18.1,50.75l9.47-10.68c-8.48-11.06-13.48-25-13.48-40.07,0-4.01,.38-7.88,1.06-11.74,.68-4.17,1.89-8.26,3.41-12.12,3.56-9.24,9.09-17.42,16.13-24.09,11.89-11.21,27.87-18.1,45.45-18.1,12.57,0,24.31,3.48,34.24,9.54l-9.54,10.68c-7.35-3.94-15.75-6.14-24.69-6.14-13.94,0-26.66,5.53-36.05,14.47-7.12,6.89-12.27,15.75-14.62,25.75h37.8l3.48,3.86,9.39,10.53h0l12.95-14.39,23.1-25.75,9.47-10.45,9.39-10.45c-3.48-3.26-7.12-6.14-11.13-8.71C111.34,4.77,96.34,0,80.14,0,58.93,0,39.61,8.26,25.3,21.74c-6.97,6.59-12.8,14.39-17.12,23.03-3.56,7.35-6.06,15.3-7.35,23.63H.83Zm36.51,62.11l-9.47,10.45c7.27,6.21,15.68,11.21,24.77,14.54,4.92,1.74,10.07,3.11,15.38,3.94,4.01,.61,8.03,.91,12.12,.91,9.7,0,18.94-1.74,27.49-4.85,9.16-3.33,17.57-8.33,24.77-14.54,17.12-14.69,27.95-36.51,27.95-60.82,0-3.94-.3-7.88-.91-11.74-.38-2.95-.98-5.91-1.74-8.71-1.36-5.23-3.26-10.15-5.6-14.92l-10.3,11.51-10.91,12.12v.08l-31.81,35.37-18.94,21.06-18.86-21.06-9.47-10.53-22.34-24.84c-.83,3.71-1.29,7.65-1.29,11.66,0,10.91,3.33,21.06,9.01,29.39,2.73,3.94,5.91,7.42,9.54,10.45,7.5,6.36,16.89,10.6,27.19,11.82,2.05,.3,4.17,.38,6.21,.38s4.24-.08,6.29-.38c2.65-.3,5.3-.83,7.8-1.51,7.2-2.05,13.79-5.6,19.39-10.3,9.24-7.73,15.75-18.71,17.88-31.13l14.39-16.06c.23,2.42,.38,4.92,.38,7.35,0,20.15-9.01,38.25-23.25,50.37-7.35,6.21-16.06,10.91-25.68,13.48-5.45,1.51-11.21,2.27-17.19,2.27h-.23c-5.91,0-11.51-.83-16.89-2.27-9.62-2.58-18.33-7.27-25.68-13.48h0Z"
                class="fill-current text-pantone-700 dark:text-white"
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <!-- Table Body -->
      <div class="card-body">
        <!-- Search & Export Controls -->
        <div class="d-flex justify-content-between mb-2 align-items-center">
          <div class="input-group search-input-group" style="max-width: 300px">
            <span class="input-group-text">
              <i class="pi pi-search"></i>
            </span>
            <InputText
              type="text"
              v-model="searchTerm"
              placeholder="Keyword Search"
              class="p-inputtext-sm form-control"
            />
          </div>
          <Button
            label="Export CSV"
            icon="pi pi-download"
            iconPos="left"
            size="small"
            class="p-button-outlined export-button"
            @click="exportCSV"
            :disabled="filteredExploits.length === 0"
          />
        </div>

        <!-- Loader -->
        <div
          v-if="loading"
          class="d-flex justify-content-center align-items-center"
          style="height: 150px"
        >
          <ProgressSpinner
            style="width: 50px; height: 50px"
            strokeWidth="4"
            fill="transparent"
            animationDuration=".5s"
          />
        </div>

        <!-- Data Table -->
        <div v-else>
          <DataTable
            ref="dataTable"
            v-if="filteredExploits.length > 0"
            :value="filteredExploits"
            :removableSort="true"
            paginator
            :rows="20"
            :rowsPerPageOptions="[10, 20, 50]"
            responsiveLayout="scroll"
            exportFilename="patchpalooza_exploits"
          >
            <!-- CVE Column -->
            <Column
              field="cve"
              header="CVE"
              :sortable="true"
              :style="{ width: '150px', 'white-space': 'nowrap' }"
            >
              <template #body="{ data }">
                <a
                  :href="
                    'https://msrc.microsoft.com/update-guide/vulnerability/' +
                    data.cve[0]
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ data.cve.join(", ") }}
                </a>
              </template>
            </Column>
            <!-- Title Column with Info Button -->
            <Column field="vulnerabilityName" header="Title" :sortable="true">
              <template #body="{ data }">
                <div class="d-flex align-items-center">
                  <span
                    class="flex-grow-1"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-right: 12px;
                    "
                  >
                    {{ data.vulnerabilityName }}
                  </span>
                </div>
              </template>
            </Column>
            <!-- Date Added Column -->
            <Column header="Date Added" field="date_added" :sortable="true">
              <template #body="{ data }">
                {{ formatDate(data.date_added) }}
              </template>
            </Column>
            <!-- Link Column -->
            <Column
              field="convertedLink"
              header="Link"
              :style="{ textAlign: 'center', width: '80px' }"
            >
              <template #body="{ data }">
                <button
                  class="btn btn-link p-0"
                  @click="openLink(data.convertedLink)"
                  title="View Repo"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-info-hexagon"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="2"
                    stroke="currentColor"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path
                      d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"
                    />
                    <path d="M12 9h.01" />
                    <path d="M11 12h1v4h1" />
                  </svg>
                </button>
              </template>
            </Column>
          </DataTable>
          <div v-else class="alert alert-warning">
            No exploits found for the search criteria.
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import config from "@/config";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";

export default {
  name: "PageExploits",
  components: { DataTable, Column, InputText, Button, ProgressSpinner },
  data() {
    return {
      exploits: [],
      searchTerm: "",
      loading: true,
      error: null,
    };
  },
  computed: {
    filteredExploits() {
      if (!this.searchTerm) return this.exploits;
      const term = this.searchTerm.toLowerCase();
      return this.exploits.filter((exp) => {
        const title = exp.vulnerabilityName
          ? exp.vulnerabilityName.toLowerCase()
          : "";
        const cve = exp.cve ? exp.cve.join(", ").toLowerCase() : "";
        const date = exp.date_added ? exp.date_added.toLowerCase() : "";
        return (
          title.includes(term) || cve.includes(term) || date.includes(term)
        );
      });
    },
  },
  mounted() {
    this.fetchExploits();
  },
  methods: {
    async fetchExploits() {
      try {
        const response = await axios.get(config.EXPLOITS_URL);
        // Add a new property "convertedLink" to each exploit for export.
        this.exploits = response.data.map((item) => ({
          ...item,
          convertedLink: this.convertCloneUrl(item.clone_ssh_url),
        }));
      } catch (err) {
        this.error = "Failed to load exploits";
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    exportCSV() {
      if (this.$refs.dataTable) {
        this.$refs.dataTable.exportCSV();
      }
    },
    formatDate(dateStr) {
      const date = new Date(dateStr);
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const year = date.getFullYear();
      return `${month}/${day}/${year}`;
    },
    convertCloneUrl(url) {
      if (!url) return "";
      if (url.startsWith("git@")) {
        // Convert "git@github.com:User/Repo.git" into "https://github.com/User/Repo"
        const parts = url.split(":");
        if (parts.length < 2) return url;
        const domain = parts[0].replace("git@", "https://");
        let path = parts[1];
        if (path.endsWith(".git")) {
          path = path.slice(0, -4);
        }
        return domain + "/" + path;
      }
      if (url.startsWith("http://")) {
        return url.replace("http://", "https://");
      }
      return url;
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.modern-card {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
