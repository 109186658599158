<template>
  <!-- Loading Overlay -->
  <div v-if="isLoading" class="loader-container">
    <div class="text-center">
      <div class="text-secondary mb-3">Loading data</div>
      <div class="progress progress-sm">
        <div class="progress-bar progress-bar-indeterminate"></div>
      </div>
    </div>
  </div>

  <div class="page-wrapper">
    <!-- Header Card -->
    <div class="container mt-3">
      <div class="card modern-card shadow-sm h-100">
        <!-- Card Header: Title -->
        <div class="card-header">
          <i class="pi pi-shield"></i>&nbsp;&nbsp;
          <h3 class="card-title">{{ documentTitle }}</h3>
        </div>
        <!-- Card Body: Patch Tuesday Toggle & Date Selector -->
        <div class="card-body">
          <div class="row align-items-center">
            <!-- Patch Tuesday Toggle -->
            <div class="col">
              <div class="d-flex align-items-center">
                <span class="me-2">Patch Tuesday</span>
                <ToggleSwitch v-model="patchTuesdayOnly" />
              </div>
            </div>
            <!-- Date Selector -->
            <div class="col-auto">
              <div class="input-group">
                <span class="input-group-text bg-white">
                  <IconCalendar
                    class="icon"
                    stroke-width="2"
                    color="currentColor"
                  />
                </span>
                <select
                  class="form-select"
                  v-if="filteredSummaries.length > 0"
                  v-model="selectedMonth"
                  @change="retrieveDataForSelectedMonth"
                >
                  <option
                    v-for="summary in filteredSummaries"
                    :key="summary.ID"
                    :value="summary.ID"
                  >
                    {{ summary.ID }}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Vulnerabilities Stats Card -->
    <div class="container mt-3">
      <div class="card modern-card shadow-sm h-100">
        <div class="card-header">
          <IconChartArrowsVertical
            class="icon"
            stroke-width="2"
            color="currentColor"
          />&nbsp;
          <h3 class="card-title">
            Vulnerabilities ({{ totalVulnerabilitiesCount }})
          </h3>
        </div>
        <div class="card-body">
          <div class="row justify-content-center">
            <div
              v-for="(stat, index) in overallStats"
              :key="index"
              class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl-2"
            >
              <div class="card card-sm no-border">
                <div class="card-body">
                  <div class="row align-items-center">
                    <div class="col-auto">
                      <span :class="['avatar', getColor(stat.title)]">
                        <span class="text-white">{{ stat.value }}</span>
                      </span>
                    </div>
                    <div class="col">
                      <div>
                        <strong>{{ stat.title }}</strong>
                      </div>
                      <div class="text-secondary">vulnerabilities</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End each stat -->
          </div>
        </div>
      </div>
    </div>

    <!-- Patch Tuesday Alert Message -->
    <div
      v-if="patchTuesdayOnly && !isValidPatchTuesdayDate"
      class="container mt-3"
    >
      <Message severity="error" icon="pi pi-times-circle" class="mb-2">
        Patch Tuesday hasn't happened yet for the current month.
      </Message>
    </div>

    <!-- Threat Cards -->
    <div
      v-if="!patchTuesdayOnly || isValidPatchTuesdayDate"
      class="container mt-3"
    >
      <div class="row">
        <ThreatCard
          title="Threat Categories"
          icon="IconVirusSearch"
          :items="sortedCategoryCounts"
          :get-emoji="getEmoji"
        />
        <ThreatCard
          title="Affected Products"
          icon="IconBrandWindows"
          :items="sortedVulnerabilityCountsByName"
          :get-emoji="getEmoji"
        />
      </div>
    </div>

    <!-- Vulnerability Tables -->
    <div
      v-for="(tableData, index) in nonEmptyTablesData"
      :key="index"
      class="container mt-3"
    >
      <div class="card modern-card shadow-sm h-100">
        <!-- Table Header -->
        <div class="card-header">
          <component
            :is="tableData.icon"
            class="icon"
            stroke-width="2"
            color="currentColor"
          />
          &nbsp;
          <h5 class="card-title">
            {{ tableData.title }} ({{
              tableData.filteredVulnerabilities.length
            }})
          </h5>
        </div>
        <!-- Table Body -->
        <div class="card-body">
          <!-- Search & Export Controls -->
          <div class="d-flex justify-content-between mb-2 align-items-center">
            <div class="input-group search-input-group">
              <span class="input-group-text">
                <i class="pi pi-search"></i>
              </span>
              <InputText
                type="text"
                v-model="state.filters[getFilterKey(tableData.title)]"
                @input="
                  state.searchPerformed[getFilterKey(tableData.title)] = true
                "
                placeholder="Keyword Search"
                class="p-inputtext-sm form-control"
              />
            </div>
            <Button
              label="Export CSV"
              icon="pi pi-download"
              iconPos="left"
              size="small"
              class="p-button-outlined export-button"
              @click="exportCSV(index)"
              :disabled="tableData.filteredVulnerabilities.length === 0"
            />
          </div>

          <!-- Data Table -->
          <!-- Use a common ref name so that Vue gathers them into an array -->
          <DataTable
            ref="dataTable"
            v-if="tableData.filteredVulnerabilities.length > 0"
            :value="tableData.filteredVulnerabilities"
            :removableSort="true"
            :paginator="true"
            :rows="10"
            :rowsPerPageOptions="[10, 20, 50]"
            responsiveLayout="scroll"
            exportFilename="patchpalooza"
          >
            <!-- CVE Column -->
            <Column
              field="CVE"
              header="CVE"
              :sortable="true"
              :style="{ width: '150px', 'white-space': 'nowrap' }"
            >
              <template #body="{ data }">
                <a
                  :href="
                    'https://msrc.microsoft.com/update-guide/vulnerability/' +
                    data.CVE
                  "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {{ data.CVE }}
                </a>
              </template>
            </Column>

            <!-- Title Column with Info Button -->
            <Column field="TitleValue" header="Title" :sortable="true">
              <template #body="{ data }">
                <div class="d-flex align-items-center">
                  <span
                    class="flex-grow-1"
                    style="
                      overflow: hidden;
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      margin-right: 12px;
                    "
                  >
                    {{ data.Title?.Value }}
                  </span>
                  <button
                    class="btn btn-link p-0"
                    @click="
                      openFaqDialog(getFAQ(data), data.CVE, data.Title.Value)
                    "
                    title="More info"
                    style="flex-shrink: 0"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-info-hexagon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path
                        d="M19.875 6.27c.7 .398 1.13 1.143 1.125 1.948v7.284c0 .809 -.443 1.555 -1.158 1.948l-6.75 4.27a2.269 2.269 0 0 1 -2.184 0l-6.75 -4.27a2.225 2.225 0 0 1 -1.158 -1.948v-7.285c0 -.809 .443 -1.554 1.158 -1.947l6.75 -3.98a2.33 2.33 0 0 1 2.25 0l6.75 3.98h-.033z"
                      />
                      <path d="M12 9h.01" />
                      <path d="M11 12h1v4h1" />
                    </svg>
                  </button>
                </div>
              </template>
            </Column>

            <!-- CVSS Column -->
            <Column
              field="CVSS"
              header="CVSS"
              :sortable="true"
              :style="{ width: '120px', 'white-space': 'nowrap' }"
            >
              <template #body="{ data }">
                <!-- Cache the CVSS calculation using our helper method -->
                <span
                  :class="[cvssData(data).severityClass, 'hover-pointer']"
                  :title="cvssData(data).severityLevel"
                >
                  <template v-if="cvssData(data).score !== 'N/A'">
                    <span @click="openCvssDialog(data.CVSSScoreSets[0])">
                      {{ cvssData(data).score }}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="icon icon-tabler icon-tabler-list-details"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M13 5h8" />
                        <path d="M13 9h5" />
                        <path d="M13 15h8" />
                        <path d="M13 19h5" />
                        <path
                          d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                        />
                        <path
                          d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                        />
                      </svg>
                    </span>
                  </template>
                  <template v-else>
                    {{ cvssData(data).score }}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-list-details"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      stroke-width="2"
                      stroke="currentColor"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M13 5h8" />
                      <path d="M13 9h5" />
                      <path d="M13 15h8" />
                      <path d="M13 19h5" />
                      <path
                        d="M3 4m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                      />
                      <path
                        d="M3 14m0 1a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4a1 1 0 0 1 -1 1h-4a1 1 0 0 1 -1 -1z"
                      />
                    </svg>
                  </template>
                </span>
              </template>
            </Column>

            <!-- Threat Category Column -->
            <Column
              field="Category"
              header="Threat Category"
              :sortable="true"
              :style="{ width: '150px', 'white-space': 'nowrap' }"
            >
              <template #body="{ data }">
                <span>{{ getCategory(data) || "Unknown Category" }}</span>
              </template>
            </Column>

            <!-- Product Column -->
            <Column
              field="Product"
              header="Product"
              :sortable="true"
              :style="{ width: '150px', 'white-space': 'nowrap' }"
            >
              <template #body="{ data }">
                <span
                  v-if="
                    data.ProductStatuses &&
                    data.ProductStatuses.length &&
                    getCategoryNameByProductId(
                      data.ProductStatuses[0].ProductID[0]
                    )
                  "
                >
                  {{
                    getCategoryNameByProductId(
                      data.ProductStatuses[0].ProductID[0]
                    )
                  }}
                </span>
              </template>
            </Column>
          </DataTable>

          <!-- No Results Message -->
          <div
            v-if="
              tableData.filteredVulnerabilities.length === 0 &&
              state.searchPerformed[getFilterKey(tableData.title)]
            "
            class="alert alert-warning"
          >
            No vulnerabilities found for the search criteria.
          </div>

          <!-- FAQ Dialog -->
          <PVDialog
            v-model:visible="showFaqDialog"
            :header="dialogHeader"
            :style="{ width: '85vw' }"
            :breakpoints="{ '1199px': '75vw', '575px': '90vw' }"
          >
            <div v-html="selectedFaqContent"></div>
            <template #footer>
              <button class="btn btn-primary" @click="showFaqDialog = false">
                Close
              </button>
            </template>
          </PVDialog>

          <!-- CVSS Dialog -->
          <PVDialog
            v-model:visible="showCvssDialog"
            header="CVSS Details"
            :style="{ width: '50vw' }"
          >
            <div class="cvss-details">
              <div class="cvss-vector-link">
                <p>
                  <strong>Vector: </strong>
                  <a
                    :href="
                      'https://www.first.org/cvss/calculator/3.1#' +
                      selectedCvssScoreSet.Vector
                    "
                    target="_blank"
                  >
                    {{ selectedCvssScoreSet.Vector }}
                  </a>
                </p>
              </div>
              <br />
              <div class="cvss-scores-table">
                <table>
                  <tbody>
                    <tr>
                      <td>Base Score:</td>
                      <td>{{ selectedCvssScoreSet.BaseScore }}</td>
                    </tr>
                    <tr>
                      <td>Temporal Score:</td>
                      <td>{{ selectedCvssScoreSet.TemporalScore }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br />
              <div class="cvss-vector-table">
                <table>
                  <tbody>
                    <tr
                      v-for="(value, key) in parseCvssVector(
                        selectedCvssScoreSet.Vector
                      )"
                      :key="key"
                    >
                      <td>{{ key }}</td>
                      <td :class="getCvssColumnClass(key, value)">
                        {{ value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <template #footer>
              <button class="btn btn-primary" @click="showCvssDialog = false">
                Close
              </button>
            </template>
          </PVDialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// =============================
// Pure Helper Functions
// =============================

/**
 * Returns the second Tuesday of the given month and year.
 * @param {number} month - Zero-based month (0 for January, etc.)
 * @param {number} year
 * @returns {Date}
 */
function getSecondTuesday(month, year) {
  const firstDayOfMonth = new Date(year, month, 1).getDay();
  const daysUntilTuesday = (2 - firstDayOfMonth + 7) % 7;
  return new Date(year, month, daysUntilTuesday + 8);
}

/**
 * Sorts summaries by year and month descending.
 * @param {Array} summaries
 * @returns {Array}
 */
function sortSummariesByMonthYear(summaries) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return summaries.sort((a, b) => {
    const [yearA, monthA] = a.ID.split("-");
    const [yearB, monthB] = b.ID.split("-");
    if (yearA !== yearB) return parseInt(yearB) - parseInt(yearA);
    return months.indexOf(monthB) - months.indexOf(monthA);
  });
}

/**
 * Parses a CVSS vector string into a key/value object.
 * @param {string} vector
 * @param {Object} metricMap
 * @returns {Object}
 */
function parseCvssVectorExternal(vector, metricMap) {
  return vector
    .split("/")
    .slice(1)
    .reduce((parsed, metric) => {
      const [abbrev, val] = metric.split(":");
      const full = metricMap[`${abbrev}:${val}`] || metric;
      const idx = full.lastIndexOf(":");
      parsed[full.substring(0, idx)] = full.substring(idx + 1).trim();
      return parsed;
    }, {});
}

const CVSS_METRIC_MAP = {
  "AV:N": "Attack Vector: Network",
  "AV:A": "Attack Vector: Adjacent",
  "AV:L": "Attack Vector: Local",
  "AV:P": "Attack Vector: Physical",
  "AC:L": "Attack Complexity: Low",
  "AC:H": "Attack Complexity: High",
  "PR:N": "Privileges Required: None",
  "PR:L": "Privileges Required: Low",
  "PR:H": "Privileges Required: High",
  "UI:N": "User Interaction: None",
  "UI:R": "User Interaction: Required",
  "S:U": "Scope: Unchanged",
  "S:C": "Scope: Changed",
  "C:N": "Confidentiality: None",
  "C:L": "Confidentiality: Low",
  "C:H": "Confidentiality: High",
  "I:N": "Integrity: None",
  "I:L": "Integrity: Low",
  "I:H": "Integrity: High",
  "A:N": "Availability: None",
  "A:L": "Availability: Low",
  "A:H": "Availability: High",
  "E:U": "Exploit Code Maturity: Unproven",
  "E:P": "Exploit Code Maturity: Proof-of-Concept",
  "E:F": "Exploit Code Maturity: Functional",
  "E:H": "Exploit Code Maturity: High",
  "RL:O": "Remediation Level: Official Fix",
  "RL:T": "Remediation Level: Temporary Fix",
  "RL:W": "Remediation Level: Workaround",
  "RL:U": "Remediation Level: Unavailable",
  "RC:U": "Report Confidence: Unknown",
  "RC:R": "Report Confidence: Reasonable",
  "RC:C": "Report Confidence: Confirmed",
};

import {
  IconBrandWindows,
  IconCalendar,
  IconChartArrowsVertical,
  IconVirusSearch,
  IconBiohazard,
  IconBiohazardOff,
} from "@tabler/icons-vue";
import { reactive } from "vue";
import InputText from "primevue/inputtext";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Button from "primevue/button";
import PrimeVueDialog from "primevue/dialog";
import ToggleSwitch from "primevue/toggleswitch";
import ThreatCard from "@/components/ThreatCard.vue";
import Message from "primevue/message";
import Toolbar from "primevue/toolbar";
import config from "@/config";

export default {
  name: "App",
  components: {
    InputText,
    Column,
    DataTable,
    Button,
    PVDialog: PrimeVueDialog,
    IconBrandWindows,
    IconCalendar,
    IconChartArrowsVertical,
    IconVirusSearch,
    IconBiohazard,
    IconBiohazardOff,
    ThreatCard,
    ToggleSwitch,
    Toolbar,
    Message,
  },
  props: {
    isPatchTuesdayPage: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const state = reactive({
      filters: {
        exploited: "",
        notExploited: "",
      },
      searchPerformed: {
        exploited: false,
        notExploited: false,
      },
    });
    return { state };
  },
  data() {
    return {
      // Loading and document title
      patchTuesdayOnly: false,
      isLoading: true,
      documentTitle: "Loading...",

      // API data and vulnerabilities
      vulnerabilities: [],
      summaries: [],
      apiData: {},

      // Patch Tuesday details
      patchTuesdayDate: "",
      isValidPatchTuesdayDate: true,

      // Maps and counts for products, categories, severities
      productCategoryMap: {},
      vulnerabilityCountsByName: {},
      categoryCounts: {},
      severityCounts: {
        Critical: 0,
        High: 0,
        Medium: 0,
        Low: 0,
        "N/A": 0,
      },
      totalVulnerabilities: 0,

      // Month selection for API queries
      selectedMonth: "",

      // UI refs and dialogs
      dataTablesRefs: {},
      showFaqDialog: false,
      selectedFaqContent: "",
      dialogHeader: "",
      showCvssDialog: false,
      selectedCvssScoreSet: null,

      // Base URL from config
      BASE_URL: config.BASE_URL,
    };
  },
  computed: {
    vulnerabilitiesByStatus() {
      const byStatus = { Exploited: [], "Not Exploited": [] };
      this.vulnerabilities.forEach((vuln) => {
        const status = this.extractSeverityAndExploitation(vuln);
        byStatus[status].push(vuln);
      });
      return {
        exploited: this.sortVulnerabilities(byStatus.Exploited),
        notExploited: this.sortVulnerabilities(byStatus["Not Exploited"]),
      };
    },
    filteredSummaries() {
      return Array.isArray(this.summaries)
        ? this.summaries.filter((summary) => summary.ID !== "2017-May-B")
        : [];
    },
    tablesData() {
      return [
        {
          title: "Exploited",
          icon: IconBiohazard,
          vulnerabilities: this.vulnerabilitiesByStatus.exploited,
        },
        {
          title: "Not Exploited",
          icon: IconBiohazardOff,
          vulnerabilities: this.vulnerabilitiesByStatus.notExploited,
        },
      ];
    },
    filteredTablesData() {
      return this.tablesData.map((table) => {
        const filterKey = this.getFilterKey(table.title);
        const filterText = this.state.filters[filterKey].toLowerCase();
        const filteredVulnerabilities = table.vulnerabilities.filter((vuln) =>
          this.matchesFilter(vuln, filterText)
        );
        return {
          ...table,
          filteredVulnerabilities,
          hasResults: filteredVulnerabilities.length > 0,
        };
      });
    },
    nonEmptyTablesData() {
      return this.filteredTablesData.filter((tableData) => {
        const filterKey = this.getFilterKey(tableData.title);
        return tableData.hasResults || this.state.filters[filterKey];
      });
    },
    sortedVulnerabilityCountsByName() {
      return Object.entries(this.vulnerabilityCountsByName || {})
        .sort((a, b) => b[1] - a[1])
        .map(([name, count]) => ({ name, count }));
    },
    overallStats() {
      return Object.keys(this.severityCounts).map((title) => ({
        title,
        value: this.severityCounts[title],
      }));
    },
    totalVulnerabilitiesCount() {
      return this.totalVulnerabilities;
    },
    sortedCategoryCounts() {
      return Object.entries(this.categoryCounts || {})
        .filter(([, count]) => count > 0)
        .sort((a, b) => b[1] - a[1])
        .map(([category, count]) => ({ category, count }));
    },
  },
  async mounted() {
    await this.retrieveAllSummaries();
    if (this.summaries.length > 0) {
      this.selectedMonth = this.summaries[0].ID;
      await this.retrieveDataForMonth(this.selectedMonth);
    }
    this.isLoading = false;
  },
  watch: {
    patchTuesdayOnly(newVal) {
      if (this.apiData && this.apiData.Vulnerability) {
        this.vulnerabilities = this.applyPatchTuesdayFilter(this.apiData);
        this.updateUIState();
      }
    },
  },
  methods: {
    // -------------------------------
    // Data Retrieval Methods
    // -------------------------------
    async retrieveAllSummaries() {
      try {
        const response = await fetch(`${this.BASE_URL}updates`, {
          headers: { Accept: "application/json" },
        });
        if (!response.ok) throw new Error("Failed to fetch summaries");
        const responseData = await response.json();
        this.summaries = sortSummariesByMonthYear(responseData.value);
      } catch (error) {
        console.error("Error fetching the summaries:", error);
      }
    },
    async retrieveDataForSelectedMonth() {
      await this.retrieveDataForMonth(this.selectedMonth);
    },
    async retrieveDataForMonth(monthId) {
      this.isLoading = true;
      this.selectedMonth = monthId;
      try {
        const response = await fetch(`${this.BASE_URL}cvrf/${monthId}`, {
          headers: { Accept: "application/json" },
        });
        if (!response.ok) throw new Error("Failed to fetch data for the month");
        this.apiData = await response.json();
        this.documentTitle = this.apiData.DocumentTitle.Value;
        this.vulnerabilities = this.applyPatchTuesdayFilter(this.apiData);
        this.updateUIState();
      } catch (error) {
        console.error(`Error fetching data for month ${monthId}:`, error);
      } finally {
        this.isLoading = false;
      }
    },
    cvssData(data) {
      return this.getCVSSScoreWithSeverity(data);
    },
    /**
     * Consolidates UI state updates after data retrieval or filter changes.
     */
    updateUIState() {
      this.productCategoryMap = this.buildProductCategoryMap(this.apiData);
      this.processVulnerabilitiesData();
      this.computeStatistics();
      this.computeVulnerabilityCountsByName();
    },
    /**
     * Applies the Patch Tuesday filter if enabled.
     * @param {Object} apiData
     * @returns {Array} Filtered vulnerabilities.
     */
    applyPatchTuesdayFilter(apiData) {
      if (!this.patchTuesdayOnly) return apiData.Vulnerability;
      this.patchTuesdayDate = apiData.DocumentTracking.InitialReleaseDate;
      if (this.isValidPatchTuesday(this.patchTuesdayDate)) {
        this.isValidPatchTuesdayDate = true;
        return apiData.Vulnerability.filter((vuln) =>
          vuln.RevisionHistory.some(
            (revision) => revision.Date === this.patchTuesdayDate
          )
        );
      } else {
        console.error("Invalid Patch Tuesday date:", this.patchTuesdayDate);
        this.isValidPatchTuesdayDate = false;
        return [];
      }
    },
    // -------------------------------
    // Helper Methods (Data Processing)
    // -------------------------------
    sortSummaries(summaries) {
      // Deprecated in favor of the external sortSummariesByMonthYear helper.
      return sortSummariesByMonthYear(summaries);
    },
    isValidPatchTuesday(dateString) {
      const date = new Date(dateString);
      const secondTuesday = getSecondTuesday(
        date.getMonth(),
        date.getFullYear()
      );
      return (
        date.getFullYear() === secondTuesday.getFullYear() &&
        date.getMonth() === secondTuesday.getMonth() &&
        date.getDate() === secondTuesday.getDate()
      );
    },
    processVulnerabilitiesData() {
      this.vulnerabilities = this.vulnerabilities.map((vuln) => ({
        ...vuln,
        TitleValue: vuln.Title?.Value || "",
        CVSS: this.getCVSSScoreWithSeverity(vuln).score,
        Category: this.getCategory(vuln) || "Unknown Category",
        Product: this.getProduct(vuln),
      }));
    },
    matchesFilter(vuln, filterText) {
      const matchText = (text) =>
        typeof text === "string" && text.toLowerCase().includes(filterText);
      return (
        matchText(vuln.CVE) ||
        matchText(vuln.Title?.Value) ||
        matchText(vuln.Category) ||
        matchText(vuln.Product) ||
        matchText(vuln.CVSS)
      );
    },
    sortVulnerabilities(vulns) {
      return vulns.sort((a, b) => {
        const aExploited =
          this.extractSeverityAndExploitation(a) === "Exploited";
        const bExploited =
          this.extractSeverityAndExploitation(b) === "Exploited";
        if (aExploited !== bExploited) return aExploited ? -1 : 1;
        const aScore = this.getCVSSScore(a);
        const bScore = this.getCVSSScore(b);
        if (aScore === "N/A" || bScore === "N/A") {
          return aScore === "N/A" ? 1 : -1;
        }
        return parseFloat(bScore) - parseFloat(aScore);
      });
    },
    buildProductCategoryMap(apiData) {
      const map = {};
      apiData.ProductTree?.Branch?.forEach((branch) => {
        branch.Items?.forEach((item) => {
          item.Items?.forEach((product) => {
            if (product.ProductID && product.Value) {
              map[product.ProductID] = item.Name;
            }
          });
        });
      });
      return map;
    },
    computeStatistics() {
      this.totalVulnerabilities = this.vulnerabilities.length;
      this.severityCounts = this.vulnerabilities.reduce(
        (acc, vuln) => {
          const { level } = this.determineSeverity(this.getCVSSScore(vuln));
          acc[level] = (acc[level] || 0) + 1;
          return acc;
        },
        { Critical: 0, High: 0, Medium: 0, Low: 0, "N/A": 0 }
      );
      const categories = [
        "Elevation of Privilege",
        "Security Feature Bypass",
        "Remote Code Execution",
        "Information Disclosure",
        "Denial of Service",
        "Spoofing",
        "Edge - Chromium",
      ];
      this.categoryCounts = categories.reduce((acc, category) => {
        acc[category] = this.vulnerabilities.filter((vuln) =>
          vuln.Threats?.some(
            (threat) =>
              threat.Type === 0 &&
              (threat.Description.Value === category ||
                (category === "Edge - Chromium" &&
                  threat.ProductID?.[0] === "11655"))
          )
        ).length;
        return acc;
      }, {});
    },
    computeVulnerabilityCountsByName() {
      this.vulnerabilityCountsByName = this.vulnerabilities.reduce(
        (acc, vulnerability) => {
          const productMap = this.productCategoryMap;
          const uniqueNames = new Set();
          vulnerability.ProductStatuses?.forEach((productStatus) => {
            productStatus.ProductID.forEach((pid) => {
              if (productMap[pid]) uniqueNames.add(productMap[pid]);
            });
          });
          uniqueNames.forEach((name) => {
            acc[name] = (acc[name] || 0) + 1;
          });
          return acc;
        },
        {}
      );
    },
    extractSeverityAndExploitation(vuln) {
      if (Array.isArray(vuln.Threats)) {
        const exploited = vuln.Threats.some((threat) =>
          threat?.Description?.Value?.includes("Exploited:Yes")
        );
        return exploited ? "Exploited" : "Not Exploited";
      }
      return "Not Exploited";
    },
    getCVSSScore(vuln) {
      return vuln.CVSSScoreSets?.[0]?.BaseScore || "N/A";
    },
    determineSeverity(cvss) {
      if (cvss === "N/A") {
        return { class: "badge badge-outline text-azure", level: "N/A" };
      }
      const score = parseFloat(cvss);
      if (score >= 9.0) {
        return { class: "badge badge-outline text-red", level: "Critical" };
      } else if (score >= 7.0) {
        return { class: "badge badge-outline text-orange", level: "High" };
      } else if (score >= 4.0) {
        return { class: "badge badge-outline text-yellow", level: "Medium" };
      } else if (score > 0.0) {
        return { class: "badge badge-outline text-green", level: "Low" };
      }
      return { class: "badge badge-outline text-azure", level: "N/A" };
    },
    getCVSSScoreWithSeverity(vuln) {
      const cvss = this.getCVSSScore(vuln);
      const severity = this.determineSeverity(cvss);
      return {
        score: cvss,
        severityClass: severity.class,
        severityLevel: severity.level,
      };
    },
    getProduct(vuln) {
      if (vuln.ProductStatuses?.length > 0) {
        const productId = vuln.ProductStatuses[0].ProductID[0];
        return this.getCategoryNameByProductId(productId) || "";
      }
      return "";
    },
    getCategoryNameByProductId(productId) {
      return this.productCategoryMap[productId] || "";
    },
    getFAQ(vuln) {
      const faqEntries =
        vuln.Notes?.filter((note) => note.Title === "FAQ") || [];
      return faqEntries.length === 0
        ? "No FAQ available"
        : faqEntries.map((faq) => faq.Value).join("<hr>");
    },
    openFaqDialog(faqContent, cve, title) {
      this.selectedFaqContent = faqContent;
      this.dialogHeader = `${cve} - ${title}`;
      this.showFaqDialog = true;
    },
    openCvssDialog(cvssScoreSet) {
      this.selectedCvssScoreSet = cvssScoreSet;
      this.showCvssDialog = true;
    },
    parseCvssVector(vector) {
      return parseCvssVectorExternal(vector, CVSS_METRIC_MAP);
    },
    getCvssColumnClass(key, value) {
      const criticalValues = ["Network", "Low", "None"];
      return criticalValues.includes(value.trim()) ? "cvss-critical" : "";
    },
    exportCSV(index) {
      const dataTables = this.$refs.dataTable;
      if (Array.isArray(dataTables) && dataTables[index]) {
        dataTables[index].exportCSV();
      } else {
        console.error("DataTable ref not found for index", index);
      }
    },
    getEmoji(name) {
      const emojiMap = {
        "Edge - Chromium": "🌐",
        "Remote Code Execution": "🎯",
        "Elevation of Privilege": "🔝",
        "Information Disclosure": "🔍",
        "Security Feature Bypass": "🛡️",
        "Denial of Service": "⛔",
        Spoofing: "🎭",
        Windows: "🪟",
        Browser: "🌐",
        ESU: "⏳",
        Azure: "☁️",
        Apps: "🛍️",
        "Developer Tools": "🛠️",
        "Microsoft Office": "📊",
        "Exchange Server": "📧",
        "SQL Server": "🗄️",
        "System Center": "🧰",
        Mariner: "🚢",
        "Microsoft Dynamics": "♾️",
        Other: "⚙️",
        Device: "💻",
        "Open Source Software": "🐧",
        "Server Software": "🖥️",
      };
      return emojiMap[name] || "";
    },
    getColor(title) {
      const colorMapping = {
        Critical: "bg-red",
        High: "bg-orange",
        Medium: "bg-yellow",
        Low: "bg-green",
        "N/A": "bg-azure",
      };
      return colorMapping[title] || "bg-secondary";
    },
    getCategory(vuln) {
      return (
        vuln.Threats?.find((threat) => threat.Type === 0)?.Description.Value ||
        "N/A"
      );
    },
    getFilterKey(title) {
      return title === "Exploited" ? "exploited" : "notExploited";
    },
  },
};
</script>

<style scoped>
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  flex-direction: column;
}

.modern-card {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.form-select {
  width: auto;
  display: inline-block;
}

.card:not(:first-child) {
  margin-top: 20px;
}

.cvss-details table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 1em;
  table-layout: fixed;
}

.cvss-details th,
.cvss-details td {
  padding: 0.5em;
  border: 1px solid #ddd;
  text-align: left;
}

.cvss-details th:first-child,
.cvss-details td:first-child {
  font-weight: bold;
  background-color: #f9f9f9;
  width: 30%;
}

.cvss-vector-link p {
  margin: 0.5em 0;
}

.cvss-vector-link a {
  color: #007bff;
  text-decoration: none;
}

.cvss-vector-link a:hover {
  text-decoration: underline;
}

.hover-pointer:hover {
  cursor: pointer;
}

.cvss-critical {
  background-color: #ffcccc;
  color: #990000;
}

.no-border {
  border: none !important;
  box-shadow: none !important;
}

.search-input-group {
  width: 300px;
}

.input-group-text {
  background-color: #fff;
  border-right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  border-left: 0;
  box-shadow: none;
}

.input-group .form-control {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-color: #dadfe5;
}

.input-group .form-control:focus {
  border-color: #dadfe5;
  box-shadow: none;
  outline: none;
}

@media (max-width: 767px) {
  .card-header {
    flex-direction: column;
  }
  .nav-item {
    width: 100%;
    justify-content: center;
  }
}
</style>
