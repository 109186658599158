import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Paginator from 'primevue/paginator';
import Ripple from 'primevue/ripple';

import MyBluePreset from './theme';
import 'primeicons/primeicons.css';

const app = createApp(App);

app.use(router);
app.use(PrimeVue, {
  ripple: true,
  theme: {
    preset: MyBluePreset,
    options: {
      prefix: 'p',
      darkModeSelector: false
    }
  }
});

app.component('DataTable', DataTable);
app.component('Column', Column);
app.component('Paginator', Paginator);

app.directive('ripple', Ripple);

app.mount('#app');
