<template>
  <!-- Top header with logo and next patch info -->
  <header class="navbar navbar-expand-md d-print-none">
    <div class="container-xl">
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbar-menu"
        aria-controls="navbar-menu"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <h1
        class="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3"
      >
        <a href="./">
          <img
            :src="logo"
            height="65"
            width="250"
            alt="Microsoft Patch-A-Palooza"
          />
        </a>
      </h1>
      <div class="badge modern-badge bg-blue-lt">
        Next Patch Tuesday: {{ nextPatchTuesday.date }}
        <span v-if="nextPatchTuesday.isToday">(today 🎉)</span>
        <span v-else>(in {{ nextPatchTuesday.remainingDays }} days)</span>
      </div>
    </div>
  </header>

  <!-- Navigation replaced with PrimeVue Menubar -->
  <Menubar :model="menuItems">
    <!-- Custom item template to use router-link -->
    <template #item="{ item, props, hasSubmenu }">
      <router-link
        v-if="item.route"
        v-slot="{ href, navigate }"
        :to="item.route"
        custom
      >
        <a v-ripple :href="href" v-bind="props.action" @click="navigate">
          <i :class="item.icon"></i>
          <span class="ml-2">{{ item.label }}</span>
        </a>
      </router-link>
      <a
        v-else
        v-ripple
        :href="item.url"
        :target="item.target"
        v-bind="props.action"
      >
        <i :class="item.icon"></i>
        <span class="ml-2">{{ item.label }}</span>
        <i v-if="hasSubmenu" class="pi pi-fw pi-angle-down"></i>
      </a>
    </template>
  </Menubar>
</template>

<script>
import logo from "@/assets/logo.png";
import Menubar from "primevue/menubar";

export default {
  name: "PageHeader",
  components: {
    Menubar,
  },
  data() {
    return {
      menuItems: [
        { label: "Patches", icon: "pi pi-home", route: "/" },
        { label: "Exploits", icon: "pi pi-bolt", route: "/exploits" },
        { label: "Statistics", icon: "pi pi-chart-bar", route: "/statistics" },
        { label: "About", icon: "pi pi-user", route: "/about" },
      ],
    };
  },
  setup() {
    return {
      logo,
    };
  },
  computed: {
    nextPatchTuesday() {
      const now = new Date();
      // Set the current time to midnight for accurate day comparisons
      now.setHours(0, 0, 0, 0);
      const currentMonth = now.getMonth();
      const currentYear = now.getFullYear();

      // Calculate the second Tuesday of the current month
      let secondTuesday = this.calculateSecondTuesday(
        currentMonth,
        currentYear
      );

      // If today's date is past the current month's second Tuesday, calculate next month's
      if (now > secondTuesday) {
        const nextMonth = currentMonth === 11 ? 0 : currentMonth + 1;
        const nextYear = currentMonth === 11 ? currentYear + 1 : currentYear;
        secondTuesday = this.calculateSecondTuesday(nextMonth, nextYear);
      }

      const isToday = now.toDateString() === secondTuesday.toDateString();
      const remainingDays = isToday
        ? 0
        : Math.ceil((secondTuesday - now) / (1000 * 60 * 60 * 24));

      return {
        date: this.formatDate(secondTuesday),
        remainingDays,
        isToday,
      };
    },
  },
  methods: {
    /**
     * Calculates the second Tuesday for a given month and year.
     * @param {number} month - Zero-based month (0 = January).
     * @param {number} year
     * @returns {Date} The date of the second Tuesday.
     */
    calculateSecondTuesday(month, year) {
      const firstDayOfMonth = new Date(year, month, 1);
      const dayOfWeek = firstDayOfMonth.getDay(); // Sunday = 0, Monday = 1, Tuesday = 2, etc.
      let firstTuesday;
      if (dayOfWeek === 2) {
        firstTuesday = firstDayOfMonth.getDate();
      } else if (dayOfWeek < 2) {
        firstTuesday = firstDayOfMonth.getDate() + (2 - dayOfWeek);
      } else {
        firstTuesday = firstDayOfMonth.getDate() + (9 - dayOfWeek);
      }
      // The second Tuesday is exactly 7 days after the first Tuesday.
      return new Date(year, month, firstTuesday + 7);
    },

    /**
     * Formats a Date object as "YYYY-MM-DD".
     * @param {Date} date
     * @returns {string}
     */
    formatDate(date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
  },
};
</script>

<style>
.modern-card {
  border: none;
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.patch-title {
  font-family: "Segoe UI", "Noto Sans", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 24px;
  font-weight: 700;
  -webkit-background-clip: text;
  color: #0166b8;
  display: inline-block;
}

.ml-2 {
  margin-left: 0.5rem;
}

/* Retain your other styles unchanged */

.modern-badge {
  font-size: 0.7rem;
  padding: 0.5rem 1rem;
  border-radius: 20px;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);
  background-color: #e9ecef;
  color: #007bff;
}

/* Add any additional Menubar overrides if desired */
</style>
